<template>
  <v-container class="m-4 mt-8">
    <v-form v-model="valid" ref="form" @submit.prevent="submit" lazy-validation>
      <v-row justify="center" class="text-center">
        <v-col sm="8">
          <v-row>
            <v-col sm="4">
              <v-text-field dense v-model="form.employee.name" outlined :rules="requiredRule()"
                :label="`${$t('hr.employee.first_name')}*`" />
            </v-col>
            <v-col sm="4">
              <v-text-field dense v-model="form.employee.middle_name" outlined :label="$t('hr.employee.mid_name')" />
            </v-col>
            <v-col sm="4">
              <v-text-field dense v-model="form.employee.last_name" outlined :label="$t('hr.employee.last_name')" />
            </v-col>
            <v-col sm="4">
              <v-text-field dense disabled v-model="form.employee.serial_number" outlined
                :label="$t('hr.employee.sign')" />
            </v-col>
            <v-col sm="4">
              <v-text-field dense v-model="form.employee.code" outlined :label="$t('hr.employee.code')" />
            </v-col>
            <v-col sm="4">
              <v-text-field type="email" dense v-model="form.employee.email" :rules="emailRule()" outlined
                :label="$t('hr.employee.email')" />
            </v-col>
            <v-col sm="4">
              <v-text-field v-if="!editMode" dense v-model="form.employee_contract.salary" :rules="requiredRule()"
                outlined :label="$t('hr.employee.base_sal')" />
            </v-col>
            <v-col sm="4">
              <v-select v-if="!editMode" :items="contracts" item-text="name" item-value="id" dense
                v-model="form.employee_contract.contract_id" outlined :rules="requiredRule()"
                :label="`${$t('hr.employee.contract')}*`" />
            </v-col>
            <v-col sm="4">
              <v-autocomplete v-if="!editMode" :items="shifts" item-text="name" item-value="id" dense
                v-model="form.employee_contract.shift_id" :rules="requiredRule()" outlined
                :label="$t('hr.employee.shift')" />
            </v-col>
            <v-col sm="4" class="mt-6">
              <v-text-field v-if="!editMode" dense v-model="form.employee_contract.leaves_in_year" :rules="requiredRule()"
                outlined :label="$t('hr.employee.required_vac')" />
            </v-col>
            <v-col sm="4">
              <div v-if="!editMode" style="padding: 2px;">{{ $t('hr.employee.emp_date') }}</div>
              <input style="
              width: 220.22px;
              height: 40px;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
              font-size: medium;
            " type="date" v-if="!editMode" v-model="form.employee.employment_date" />
            </v-col>
            <v-col sm="4">
              <div v-if="!editMode" style="padding: 2px;">{{ $t('hr.employee.payment_start_date') }}</div>
              <input style="
              width: 220.22px;
              height: 40px;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
              font-size: medium;" type="date" v-if="!editMode" v-model="form.employee_contract.created_at"
                :rules="requiredRule()" :label="$t('hr.employee.payment_start_date')" />
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="4">
          <input type="file" ref="fileInput" style="display: none" @change="handleFileChange" />
          <div @click="openFilePicker">
            <img v-if="imageUrl" :src="imageUrl" alt="Selected Image"
              style="cursor: pointer; width: 300px; height: 300px; object-fit: cover;" />
            <img v-else-if="form.employee.personal_pic" :src="getImgUrl(form.employee.personal_pic)" alt="Selected Image"
              style="cursor: pointer; width: 300px; height: 300px; object-fit: cover;" />
            <img v-else src="@/assets/pricing.jpg" alt="Placeholder Image"
              style="cursor: pointer; width: 300px; height: 300px; object-fit: cover;" />
          </div>
        </v-col>
        <v-col sm="12">
          <v-divider></v-divider>
        </v-col>
        <v-col sm="12" style="padding-left: 12rem">
          <v-row>
            <v-col sm="3" class="py-0">
              <v-radio-group v-model="form.employee.gender" :rules="requiredRule()" row :label="$t('hr.employee.gender')">
                <v-radio :label="$t('male')" value="male" color="#757575"></v-radio>
                <v-radio :label="$t('female')" value="female" color="#757575"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.nationlaty" :rules="requiredRule()" outlined
                :label="$t('hr.employee.nationality')" />
            </v-col>
            <v-col sm="6">
              <v-text-field dense v-model="form.employee.address" :rules="requiredRule()" outlined
                :label="$t('hr.employee.address')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.mother_name" outlined :label="$t('hr.employee.mother_name')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.passport_number" outlined :label="$t('hr.employee.passport')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.id" outlined :label="$t('hr.employee.id')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.note" outlined :label="$t('hr.employee.note')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.iban" outlined :label="$t('hr.employee.iban')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.brch_swift" outlined :label="$t('hr.employee.swift')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.branch_number" outlined :label="$t('hr.employee.branch_num')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.branch_name" outlined :label="$t('hr.employee.branch_text')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.coin" outlined :label="$t('hr.employee.currency')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.acount_number" outlined
                :label="$t('hr.employee.account_number')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.job" outlined :label="$t('hr.employee.job')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.zone" outlined :label="$t('hr.employee.area')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.location" outlined :label="$t('hr.employee.location')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.alter_phone_number" outlined
                :label="$t('hr.employee.alter_phone')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.phone_number" outlined :label="$t('hr.employee.phone')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.phone_number2" outlined :label="$t('hr.employee.phone2')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.guarantee_number" outlined :label="$t('hr.employee.ssn')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.guarantee_salary" outlined
                :label="$t('hr.employee.security_salary')" />
            </v-col>
            <v-col sm="3">
              <v-text-field dense v-model="form.employee.guarantee_card" outlined
                :label="$t('hr.employee.security_id_number')" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-btn color="green" class="white--text" type="submit">{{ $t('save') }}
      </v-btn>
    </v-form>
  </v-container>
</template>
<script>
import { emailRule, requiredRule } from "@/helpers/validation-rules";
import { mapActions, mapGetters } from "vuex";
// import imageInputFile from "../../../../components/base/imageInputFile.vue";

export default {
  // components: {
  //   appImageInputFile: imageInputFile,
  // },
  computed: {
    ...mapGetters({
      shifts: 'employees/shifts',
      contracts: 'employees/contracts',
    })
  },
  mounted() {
    if (this.$store.state.employees.selectedEmployee) {
      this.form = {
        employee: { ...this.$store.state.employees.selectedEmployee }
      }
      this.editMode = true
    }
    this.fetchShifts()
    this.fetchContracts()
  },
  methods: {
    getImgUrl(item) {
      let domain = "https://platform-back.incentive-dev.com/"
      let item2 = item.replace("public", "storage")
      return domain + item2
    },
    emailRule() {
      return emailRule
    },
    requiredRule() {
      return requiredRule
    },
    ...mapActions({
      store: 'employees/store',
      update: 'employees/update',
      fetchContracts: 'employees/fetchContracts',
      fetchShifts: 'employees/fetchShifts',
    }),
    async submit() {
      const valid = await this.$refs.form.validate()
      if (valid) {
        if (this.form.employee.id) {
          console.log(this.form);
          let data = this.form.employee
          var res = await this.update(this.form.employee);
          if (res.status == 200) {
            this.$router.go(-1);
            this.$Notifications(
              "تمت التعديل بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
        } else {
          var res = await this.store(this.form);
          if (res.status == 200) {
            this.$router.go(-1);
            this.$Notifications(
              "تمت الحفظ بنجاح",
              { rtl: true, timeout: 2500 },
              "success"
            );
          }
        }
      }
    },
    openFilePicker() {
      this.$refs.fileInput.click();
    },
    async handleFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.includes('image')) {
        this.form.employee.personal_pic = file
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.imageUrl = e.target.result;
          this.form.employee.personal_pic = reader.result;
        };
      } else {
        // Handle the case when the selected file is not an image
        // You can show an error message or reset the form field
        this.form.employee.personal_pic = null;
        this.imageUrl = null;
      }
    },
  },
  data() {
    return {
      editMode: false,
      imageUrl: null,
      form: {
        employee: {
          personal_pic: null,
          name: '',
          middle_name: '',
          last_name: '',
          null_serial_number: '',
          code: '',
          email: '',
          gender: '',
          nationlaty: '',
          address: '',
          mother_name: '',
          passport_number: '',
          id: '',
          note: '',
          iban: '',
          brch_swift: '',
          branch_number: '',
          branch_name: '',
          coin: '',
          acount_number: '',
          job: '',
          zone: '',
          location: '',
          alter_phone_number: '',
          phone_number: '',
          phone_number2: '',
          guarantee_number: '',
          guarantee_salary: '',
          guarantee_card: '',
          employment_date: '',
        },
        employee_contract: {
          salary: '',
          contract_id: '',
          shift_id: '',
          leaves_in_year: '',
          created_at: '',
        }
      },
      valid: false
    };
  },
};
</script>
